import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContactInfoType } from '../../types';
import ContactForm from './ContactForm';
import api from '../../api/api';
import { Button, PageHeader } from 'antd';

const Contact = () => {
  //
  const navigate = useNavigate();

  if (!localStorage.getItem('token')) {
    navigate('/login', { replace: true });
  }

  //
  const { t } = useTranslation();

  //
  const [contactInfo, setContactInfo] = useState<ContactInfoType>();

  useEffect(() => {
    getContactInfo();
  }, []);

  //
  const getContactInfo = async () => {
    const resp: ContactInfoType = await api('api/contact-info');
    setContactInfo(resp);
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title={t('Contact Info')}
        style={{
          borderBottom: '20px solid #f0f2f5',
          position: 'sticky',
          top: 0,
          zIndex: 100,
        }}
        extra={[
          <Button
            type='primary'
            form={`contact-form`}
            htmlType='submit'
            size='large'
          >
            {t('Save')}
          </Button>,
        ]}
      />

      <div className='nl-box'>
        {contactInfo && <ContactForm data={contactInfo} />}
        {!contactInfo && <ContactForm />}
      </div>
    </>
  );
};

export default Contact;
