import { Result, Spin } from "antd";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Contact from "../pages/Contact";

// LAZY LOAD COMPONENTS
const Dashboard = lazy(() => import("../pages/Dashboard"));

const Users = lazy(() => import("../pages/Users"));
const EditUser = lazy(() => import("../pages/Users/EditUser"));

const Staffs = lazy(() => import("../pages/Staff"));
const EditStaff = lazy(() => import("../pages/Staff/EditStaff"));

const Terms = lazy(() => import("../pages/Terms"));
const EditTerm = lazy(() => import("../pages/Terms/EditTerm"));

const Blog = lazy(() => import("../pages/Blog"));
const EditBlog = lazy(() => import("../pages/Blog/EditBlog"));

const Reports = lazy(() => import("../pages/Reports"));

const Categories = lazy(() => import("../pages/BlogCategories"));

const Info = lazy(() => import("../pages/ProfessionalInformation"));
const EditInfo = lazy(() => import("../pages/ProfessionalInformation/EditInfo"));
const Settings = lazy(() => import("../pages/Settings"));
const PlanSettings = lazy(() => import("../pages/Settings/PlanSettings"));
const SalarySettings = lazy(() => import("../pages/Settings/salaryControl/index"));
const PricePlanSettings = lazy(() => import("../pages/Settings/PricePlan/index"));

const AppRouter = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:userId" element={<EditUser />} />
        <Route path="/staff" element={<Staffs />} />
        <Route path="/staff/:staffId" element={<EditStaff />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/terms/create" element={<EditTerm />} />
        <Route path="/terms/:termId" element={<EditTerm />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/create" element={<EditBlog />} />
        <Route path="/blogs/:blogId" element={<EditBlog />} />
        <Route path="/articles" element={<Blog />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/info" element={<Info />} />
        <Route path="/info/create" element={<EditInfo />} />
        <Route path="/info/:infoId" element={<EditInfo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/plan-settings" element={<PlanSettings />} />
        <Route path="/salary-control-settings" element={<SalarySettings />} />
        <Route path="/price-plan-settings" element={<PricePlanSettings />} />
        <Route element={<Result status="404" title="404" />} />;
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
