import { DataNode } from 'antd/lib/tree';

export type ValidationError = {
  field: string;
  message: string;
};

export type TypeFile = {
  created_at: number;
  extention: string;
  mime_type: string;
  size: number;
  title: string;
  _id: string;
  url: string;
};

export type Admin = {
  _id: string;
  username: string;
  email: string;
  status: number;
  role: AdminRoles;
  phone: string;
};

export type Template = {
  _id: string;
  plan: string;
  subject: string;
  body: string;
  created_at: number;
  updated_at: number;
  type : string
};

export type TemplatesResponse = Template[];

export enum AdminRoles {
  SUPER_ADMIN = 0,
  ADMIN = 10,
  MANAGER = 1,
}

export enum UserType {
  CLINIC = 1,
  DEALER = 10,
  DISTRIBUTOR = 100,
}

export type User = {
  is_verified: boolean;
  _id: string;
  avatar: string | null;
  created_at: number;
  email: string;
  fname: string;
  lname: string;
  status: number;
  facebook_id: string;
  google_id: string;
  apple_id: string;
  
  type: UserType;
  family_id: string | null;
  phone: string;
  city: string | null;
  dob: number | null;
  isWorkVisaValid: boolean | null;
  credits?: number;
  patients?: Patient[];
  validContract?: Contract;
  allContracts?: Contract[]
  planSetupDate?: number 

};

export enum ContractStatus {
  WAITING_FOR_STAFF_SIGNATURE = 10,
  VALID = 100,
  REJECTED = -10,
  FINISHED = 0,
  FROZEN = 50,
}

export type Contract = {
  _id: string;
  patient_id: string;
  staff_id: string;
  status: ContractStatus;
  employeeType: number;
  dealStartDate: number | null;
  planSetupDate?: number | undefined
holidaysChoice?: number,
pensions?: number,
  // workOnRestDay: WorkOnRestDayStatus;
  weeklyRestDayCap: number | null;
  baseSalary: number | null;
  weeklyDayOff: number;
  monthlyAllowanceCash: number | null;
  weeklyAllowanceSupplement: number | null;
  otherLiabilities: boolean;
  otherLiabilitiesPerMonth: number | null;
  // otherLiabilitiesOption: OtherLiabilitiesStatus | null;
  choiceVacation: number;
  recoverySelection: number;
  // holidaysChoice: HolidaysChoiceStatus;
  // pensions: PensionsStatus;
  allowanceRight: number;
  fundingType: number;
  familySignature: string | null;
  staffSignature: string | null;
  isValidInsurance: boolean;
  insuranceDate: number | null;
  user?: User;
  user_data?: User;
  staff_data?: User;

  staff?: User;
  pdf?: string | undefined;

  terminationPdf?: string;

  patient?: Patient;

  dealEndDate?: number;

};

export type Report = {
  type: 'report' | 'termination';

  // report
  _id: string;
  accumulatedCompensationAmount: number;
  accumulatedRetirementAmount: number;
  accumulatedRecoveryDaysForYear: number;
  accumulatedSickDaysForThisMonth: number;
  accumulatedVacationDaysForThisMonth: number;
  baseSalary: number;
  compensationPayment: number;
  contract_id: string;
  created_at: number;
  daysWorkedOnHoliday: number;
  deductions: number;
  holydays: { [key: string]: string };
  month: number;
  number: number;
  optionalWorkingDays: number;
  otherLiabilities: boolean;
  // otherLiabilitiesOption: OtherLiabilitiesStatus | null;
  otherLiabilitiesPerMonth: number;
  // pensions: PensionsStatus;
  pensionAndSocialInsurance: number;
  retirementPayment: number;
  salaryForHolidayDays: number;
  salaryForPocketExpenses: number;
  salaryForRecoveryPerMonth: number;
  salaryForRecoveryPerYear: number;
  salaryForSickDays: number;
  salaryForVacation: number;
  salaryForWeekend: number;
  salaryForWorkingDays: number;
  salaryGross: number;
  selectedDayOff: string;
  socialInsurancePayments: number;
  status: ReportStatus;
  updated_at: number;
  usedSickDaysThisMonth: number;
  vacationDaysSpentThisMonth: number;
  weekendDaysThisMonth: number;
  monthlyAllowanceCash: number;
  weeklyAllowanceSupplement: number;
  weeklyDayOff: number;
  workExperience: number;
  workedDays: number;
  workedWeekends: number;
  year: number;
  paidAdvance?: number;
  sisterCompanyPayment?: number;
  staff?: User;

  user?: User;
  dealStartDate: number;
  salaryNetto: number;
  totalEmployeeCostPerMonth: number;
  restOfVacation: number;
  currentVacationBalance: number;
  payEveryMonthRecovery: number;
  restOfSickDays: number;
  currentSickDaysBalance: number;
  patient?: Patient;

  // termination
  accumulatedVacation?: number;
  // advanceNotice?: AdvanceNoticeStatus;
  lastSalaryNetto?: number;
  nursingSociety?: boolean;
  pensionAmount?: number;
  terminationTotal?: number;
  dealEndDate?: number;
};

export enum ReportStatus {
  WAITING = 10,
  DONE = 100,
}

export type TermType = {
  _id: string;
  title: string;
  content: string;
  date: number;
  type: number;
  status: number;
};

export type ContactInfoType = {
  phone: string;
  address: string;
  email: string;
  whatsapp: string;
};

export enum TermsType {
  PRIVACY = 10,
  TERMS = 100,
}

export type Blog = {
  _id: string;
  category: string;
  title: string;
  subtitle: string;
  text: string;
  date: number;
  image: string | null;
};

export type Info = {
  _id: string;
  title: string;
  subtitle: string;
  text: string;
  date: number;
  image: string | null;
};

export type Patient = {
  _id: string;
  fname: string;
  lname: string;
  city: string;
  age: number | null;
  email: string;
  // language: AppLanguages | null;
  // gender: GenderOptions;
  employmentPermitDate: number | null;
  passportNumber: string | null;
};

export type Category = {
  _id: string;
  title: string;
  //order:
};

// export type Option = {
//   id: number;
//   title: string;
//   status: number;
//   values?: OptionValue[];
// };

// export type OptionValue = {
//   id: number;
//   option_id: number;
//   title: string;
//   sort: string;
//   status: number;
// };

// export interface Category extends DataNode {
//   id: number;
//   title: string;
//   children: Category[];
//   parent_id: string | null;
//   status: number;
//   productsCount: number;
//   values?: OptionValue[];
//   header_image: string | null;
//   promotion_image: string | null;
//   promotion: boolean;
//   color: string;
// }

// export type Brand = {
//   id: number;
//   title: string;
//   status: number;
//   promotion: boolean;
//   logo: string | null;
// };

// export type Product = {
//   id: number;
//   title: string;
//   slug: string;
//   status: number;
//   sku?: string;
//   released: number;

//   description: string;

//   images: string[];
//   categories: string[];
//   options: [
//     {
//       option_id: number;
//       values_id: number[];
//     }
//   ];

//   variants?: {
//     values: { option: number; value: number }[];
//     sku?: string;
//     price: number;
//     sale_price?: number;
//     stock: number;
//     sale_from?: number | null;
//   }[];
//   variants_10?: {
//     values: { option: number; value: number }[];
//     sku?: string;
//     price: number;
//     sale_price?: number;
//     stock: number;
//     sale_from?: number | null;
//   }[];
//   variants_100?: {
//     values: { option: number; value: number }[];
//     sku?: string;
//     price: number;
//     sale_price?: number;
//     stock: number;
//     sale_from?: number | null;
//   }[];

//   brand: Brand | null;
//   brand_id: string | null;

//   top: boolean;
//   sale: boolean;

//   with_categories: Category[];
// };

// export type TermType = {
//   id: string;
//   title: string;
//   content: string;
//   date: number;
//   type: number;
//   status: number;
// };

// export enum TermsType {
//   PRIVACY = 10,
//   TERMS = 100,
// }

// export enum UserType {
//   CLINIC = 1,
//   DEALER = 10,
//   DISTRIBUTOR = 100,
// }

// export type User = {
//   avatar: string | null;
//   created_at: number;
//   email: string;
//   fname: string;
//   lname: string;
//   status: number;
//   id: number;
//   facebook_id: string;
//   google_id: string;
//   apple_id: string;
//   type: UserType;
//   credits?: number;
// };

// export type Order = {
//   id: number;
//   user: User;
//   status: number;
//   products: OrderProduct[];
//   address_id: number;
//   shipment_type: ShipmentType;
//   shipment_price: number;
//   created_at: number;
//   address: Address;
//   total: number;

//   catalog_products?: Product[];
//   history: OrderHistory[];

//   credits_payed: number;

//   payment_info?: {
//     TranzilaTK: string;
//     cardtype: number;
//   };
// };

// export type Order = {
//   id: number;
//   number: string;
//   status: number;
//   user_id: number | null;
//   account: User | null;
//   address: {
//     flat: string;
//     building: string;
//     city: string;
//     country: string;
//     street: string;
//     zip: string;
//   };
//   products: OrderProduct[];
//   total: number;
//   currency: string;
//   user: {
//     email: string;
//     first_name: string;
//     last_name: string;
//     phone: string;
//   };
//   created_at: number;
//   // payment: PaymentInfo;

//   shipment_id?: string;
//   // shipment: Shipment | null;
//   shipment_price?: number;

//   history: OrderHistory[];
// };

// export type OrderProduct = {
//   product_id: number;
//   variant: {
//     option_id: number;
//     value_id: number;
//   }[];
//   quantity: number;
//   price: number;
// };

// export type OrderProduct = {
//   discount: number;
//   price: number;
//   product_id: string;
//   quantity: number;
//   sale_price: number;
//   variant?: any;
//   product: OrderProductProduct;
// };

// export type OrderProductProduct = {
//   id: number;
//   title: string;
//   sku: string | null;
//   slug: string;
//   status: number;
//   price: number;
//   sale_price: number | null;
//   sale_from: number | null;
//   price_min: number;
//   price_max: number;

//   short: string;
//   description: string;

//   images: string[];
//   categories: string[];

//   options: ProductOption[];

//   // properties: {
//   //   property: Property;
//   //   value: PropertyValue;
//   // }[];
//   // variants: Variant[];
//   // variants_images: {
//   //   value: string;
//   //   images: TypeFile[];
//   // }[];

//   // stock: number;

//   // real_price: number;
// };

// export type ProductOption = {
//   id: number;
//   title: string;
//   values?: OptionValue[];
// };

// export type OrderHistory = {
//   created_at: number;
//   notify: boolean;
//   status: number;
//   text: string;
// };

// export type Address = {
//   id: number;
//   title: string;
//   fname: string;
//   lname: string;
//   city: string;
//   street: string;
//   house: string;
//   appartment: string;
//   state: string;
//   country: string;
//   zip: string;
//   phone: string;
// };

// export enum ShipmentType {
//   NO_SHIPMENT = 0,
//   REGULAR = 10,
//   EXPRESS = 50,
// }

// export type FAQ = {
//   id: number;
//   question: string;
//   answer: string;
//   created_at: number;
//   updated_at: number;
//   status: number;
//   position: number;
// };

// export type Banner = {
//   id: number;
//   created_at: number;
//   updated_at: number;
//   image: string | null;
//   position: number;
//   status: BannerStatus;
// };

// export enum BannerStatus {
//   NOT_ACTIVE = 9,
//   ACTIVE = 10,
// }

// export type ForumPost = {
//   id: number;
//   created_at: number;
//   title: string;
//   description: string;
//   topic: string;
//   photo: string | null;
//   status: ForumStatus;
//   myLike_id: number | null;
//   likes: number;

//   user_id: number;
//   comments_count: number;

//   user: User;
// };

// export enum ForumStatus {
//   NOT_ACTIVE = 0,
//   ACTIVE = 10,
// }

// export type Comment = {
//   id: number;
//   post_id: string;
//   created_at: number;
//   text: string;

//   user_id?: string;
//   user: User;
// };

// export type Message = {
//   id: number;
//   title: string;
//   text: string;
//   created_at: number;
//   status: MessageStatus;
// };

// export enum MessageStatus {
//   NOT_ACTIVE = 0,
//   ACTIVE = 10,
// }

// export type Credit = {
//   id: number;
//   created_at: number;
//   user_id: number;
//   amount: number;
//   comment: string;
//   type: CreditType;
//   user?: User;
// };

// export enum CreditType {
//   TYPE_IN = 1,
//   TYPE_OUT = -1,
// }
