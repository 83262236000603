import { Col, Form, Input, notification, Row, Spin } from 'antd';
import api from '../../api/api';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactInfoType } from '../../types';

const { Item } = Form;

const ContactForm: React.FC<{
  data?: ContactInfoType | null;
}> = ({ data }) => {
  //
  const navigate = useNavigate();

  if (!localStorage.getItem('token')) {
    navigate('/login', { replace: true });
  }

  //
  const { t } = useTranslation();

  //
  const [form] = Form.useForm();

  //
  const [loading, setLoading] = useState(false);

  //
  //const handleEditorChange = (content: string) => {
  //  form.setFieldsValue({ content: content });
  //};

  console.log('data: ', data);

  //
  const sendData = async (values: any) => {
    setLoading(true);

    const resp: { error?: { field: string; message: string }[] } = await api(
      'api/contact-info',
      'POST',
      values
    );

    setLoading(false);

    if (resp.error) {
      resp.error.map((item) => {
        form.setFields([
          {
            name: item.field,
            errors: [item.message],
          },
        ]);
      });

      return;
    }

    navigate('/contact', { replace: true });

    notification['success']({
      message: t('Success'),
      description: t('Data saved sucessfully!'),
    });
  };

  const onFinishFailed = async (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name='basic'
      initialValues={!!data ? data : {}}
      labelAlign='right'
      id='contact-form'
      onFinish={sendData}
      onFinishFailed={onFinishFailed}
      form={form}
      //key={}
    >
      <Row>
        <Col span={12}>
          <Item label={t('Phone')} name='phone' rules={[{ required: true }]}>
            <Input placeholder={t('Phone')} />
          </Item>
          <Item
            label={t('Address')}
            name='address'
            rules={[{ required: true }]}
          >
            <Input placeholder={t('Address')} />
          </Item>
          <Item label={t('Email')} name='email' rules={[{ required: true }]}>
            <Input placeholder={t('Email')} />
          </Item>
          <Item label={t('Whatsapp')} name='whatsapp' rules={[{ required: true }]}>
            <Input placeholder={t('Whatsapp')} />
          </Item>
        </Col>
      </Row>

      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255,0.5)',
            zIndex: 101,
          }}
        >
          <Spin />
        </div>
      )}
    </Form>
  );
};

export default ContactForm;
