import { Button, Menu, Popconfirm } from "antd";
import React, { ReactNode, useContext, useState } from "react";
import {
  FileTextOutlined,
  TeamOutlined,
  QuestionCircleOutlined,
  FileExclamationOutlined,
  FileOutlined,
  FolderOutlined,
  LaptopOutlined,
  PhoneOutlined,
  SettingOutlined,
  SettingFilled,
  SettingTwoTone,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CommonStore from "../../store/CommonStore";
import { AdminRoles } from "../../types";
import { Link, useLocation } from "react-router-dom";
import api from "../../api/api";

type MenuType = {
  key: string;
  label: string | ReactNode;
  icon: ReactNode;
  items?: { key: string; label: string | ReactNode; icon: ReactNode }[];
};

const { SubMenu, Item } = Menu;

const NavigationBar: React.FC<{ theme: "dark" | "light" }> = ({ theme }) => {
  //
  const { t } = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const location = useLocation();

  //
  const menu: { [key: number]: MenuType[] } = {
    [AdminRoles.SUPER_ADMIN]: [
      {
        key: "users",
        label: t("Users"),
        icon: <TeamOutlined />,
      },
      {
        key: "staff",
        label: t("Staff"),
        icon: <TeamOutlined />,
      },
      {
        key: "reports",
        label: t("Reports"),
        icon: <FileExclamationOutlined />,
      },
      {
        key: "blogs",
        label: t("Blog"),
        icon: <FileTextOutlined />,
        items: [
          {
            key: "articles",
            label: t("Articles"),
            icon: <FileOutlined />,
          },
          {
            key: "categories",
            label: t("Categories"),
            icon: <FolderOutlined />,
          },
        ],
      },
      {
        key: "info",
        label: t("Professional information"),
        icon: <LaptopOutlined />,
      },
      {
        key: "terms",
        label: t("Terms and Privacy"),
        icon: <QuestionCircleOutlined />,
      },
      {
        key: "contact",
        label: t("Contact Info"),
        icon: <PhoneOutlined />,
      },
      {
        key: "settings",
        label: t("Settings"),
        icon: <SettingOutlined />,
        items: [
          {
            key: "settings",
            label: t("General settings"),
            icon: <SettingFilled />,
          },
          {
            key: "plan-settings",
            label: t("Plan Settings"),
            icon: <SettingTwoTone />,
          },
          {
            key: "salary-control-settings",
            label: t("Price Plan Settings"),
            icon: <SettingTwoTone />,
          },
          {
            key: "price-plan-settings",
            label: t("price plan"),
            icon: <SettingTwoTone />,
          },
        ],
      },
    ],
  };

  const activeRoute = location.pathname.split("/")[1] || "";

  //
  const openedKey = () => {
    let ret = "";
    if (storeCommon.user) {
      menu[storeCommon.user.role].forEach((item) => {
        const search = item.items?.find((sub) => {
          return sub.key.split("/")[0] === activeRoute;
        });
        if (search) {
          ret = item.key;
          return;
        }
      });
    }
    return ret;
  };

  return (
    <>
      <Menu
        // onClick={this.handleClick}
        id={`menu-${storeCommon.user?._id}`}
        style={{ width: 256 }}
        mode="inline"
        theme={theme}
        defaultOpenKeys={[openedKey()]}
        defaultSelectedKeys={[activeRoute]}
        key={`menu-${activeRoute}`}
      >
        {storeCommon.user &&
          menu[storeCommon.user.role].map((item, index) => {
            return (
              <React.Fragment key={index}>
                {!item.items && (
                  <Item icon={item.icon} key={item.key}>
                    <Link to={`/${item.key}`}>{item.label}</Link>
                  </Item>
                )}
                {item.items && (
                  <SubMenu key={item.key} icon={item.icon} title={item.label}>
                    {item.items.map((sub) => {
                      return (
                        <Item icon={sub.icon} key={sub.key}>
                          <Link to={`/${sub.key}`}>{sub.label}</Link>
                        </Item>
                      );
                    })}
                  </SubMenu>
                )}
              </React.Fragment>
            );
          })}
      </Menu>
      <Popconfirm
        placement="top"
        title={t("Are you sure?")}
        onConfirm={async () => {
          try {
            const resp = await api("reports/month-report", "POST");
            console.log("resp >>>>>>", resp);
          } catch (error: any) {
            console.log("Api error: ", error.toString());
          }
        }}
        okText={t("Yes")}
        cancelText={t("No")}
      >
        <Button type="primary" style={{ margin: "30px 20px 0" }}>
          {t("Generate reports")}
        </Button>
      </Popconfirm>
    </>
  );
};

export default NavigationBar;
