const he = {
  translation: {
    "User Name": "שם משתמש",
    "Register date": "תאריך הרשמה",
    help: "מידע",

    "Showing ${range[0]} - ${range[1]} of ${total} records":
      "מוצגים {{start}} - {{end}} מתוך {{total}} רשומות",
    "User details": "פרטי הלקוח",
    "Add new terms or privacy": "הוספת תקנון חדש",
    Date: "תאריך",
    "Terms and Privacy": "תקנון האתר",
    "Contact Info": "פרטים ליצירת קשר",
    Privacy: " האתר",
    "Add new terms": "הוספת תקנון חדש",
    Users: "משתמשים",
    Staff: "צוות",
    Category: "קטגוריה",
    Image: "תמונה",
    "Add new blog": "הוסף בלוג חדש",
    Blogs: "בלוגים",
    Subtitle: "כתובית",
    Text: "טקסט",
    Blog: "בלוג",

    //
    "{{field}} must be between {{min}} and {{max}": "{{field}} חייב להיות בין {{min}} ל-{{max}",
    "{{field}} must be between {{min}} and {{max}} characters":
      "{{field}} חייב להיות בין {{min}} ל-{{max}} תווים",
    "{{field}} must be correct email address": '{{field}} חייבת להיות כתובת דוא"ל נכונה',
    "{{field}} must be correct ID": "{{field}} חייב להיות מזהה נכון",
    "{{field}} must be correct mobile phone number": "{{field}} חייב להיות מספר טלפון נכון",
    "{{field}} must be grater {{min}} characters": "{{field}} חייב להיות גדול מ{{min}} תווים",
    "{{field}} must be grater then {{min}}": "{{field}} חייב להיות גדול מ{{min}}",
    "{{field}} must be less {{max}} characters": "{{field}} חייב להיות פחות {{max}} תווים",
    "{{field}} must be less then {{max}}": "{{field}} חייב להיות פחות מ-{{max}}",
    "{field}} must be correct phone number": "{field}} חייב להיות מספר טלפון נכון",
    "14 days trial period": "תקופת ניסיון של 14 ימים",
    Сommercial: "מסחרי",
    Сontinue: "המשך",
    Active: "פעיל",
    active: "פעיל",
    Address: "רחוב, מס׳ בית",
    Add: "להוסיף",
    "Add agent": "הוסף סוכן",
    "Add client": "הוסף לקוח",
    "Add Contact": "הוסף איש קשר",
    "Add logo": "הוסף לוגו",
    "Add new": "הוסף חדש",
    "Add new property": "הוסף נכס חדש",
    "Add photo": "הוסף תמונה",
    "Add property": "הוסף נכס",
    "Add task": "הוסף משימה",
    Additionally: "בנוסף",
    AddTask: "הוסף משימה",
    "Are you sure?": "אתה בטוח?",
    Mine: "שלי",
    Newest: "הכי חדש",
    "Agency name": "שם הסוכנות",
    Agent: "סוכן",
    Agents: "סוכנים",
    All: "את כל",
    and: "ו",
    "Add contact": "הוספת איש קשר",
    Alphabetical: "סדר אלף ביתי",
    Tenant: "שוכר",
    Landlord: "משכיר",
    Buyer: "קונה",
    "Buyer Seller": "קונה - מוכר",
    Apartments: "דירות",
    Apply: "הירשם",
    Apt: "דירה",
    Area: "אֵזוֹר",
    "Area m²": 'שטח מ"ר',
    "Area m²__1": 'שטח מ"ר',
    "Associate with buyer": "שייך לקונה",
    Back: "חזור",
    Balcony: "מרפסת שמש",
    Building: "בנין",
    Buy: "לִקנות",
    Buyers: "קונים",
    "By clicking the create account button, I accept the":
      "על ידי לחיצה על כפתור צור חשבון, אני מקבל את",
    Calendar: "יומן",
    calls: "שיחות",
    Cancel: "בטל",
    "Cancel subscription": "בטל רישום",
    "Change number": "עדכן מספר",
    "Change Status": "עדכן סטטוס",
    "Choose Plan": "בחר תוכנית",
    "Choose Your Plan": "בחר את התוכנית שלך",
    "Choose Your Role": "בחר את התפקיד שלך",
    Cities: "ערים",
    "No calls": "אין שיחות נכנסות",
    Menu: "תפריט",
    City: "עיר",
    Clear: "נקה",
    Completed: "הושלם",
    Contact: "איש קשר",
    "Contact matches": "התאמות ללקוח",
    Contacts: "אנשי קשר",
    "Create a new agency": "צור סוכנות חדשה",
    "Create account": "יצירת חשבון",
    "Create Account": "יצירת חשבון",
    Current: "נוכחי",
    "Date of leave": "תאריך פינוי",
    Description: "תיאור",
    Dial: "חייג",
    "Master bedroom": "יחידת הורים",
    Done: "בוצע",
    Edit: "לַערוך",
    "Edit Contact": "ערוך איש קשר",
    "Edit Property": "ערוך נכס",
    "Edit Request": "ערוך בקשה",
    Elevator: "מַעלית",
    "Elevator above second floor": "מעלית מעל הקומה השנייה",
    Email: "אימייל",
    "Enter the invitation code": "הזן את קוד ההזמנה",
    "Enter Verification Code": "הכנס קוד אימות",
    "Enter your phone number to log in": "הזן את מספר הטלפון שלך כדי להיכנס",
    Filters: "מסננים",
    "First name": "שם פרטי",
    Floor: "קומה",
    floor: "קומה",
    "For investment": "להשקעה",
    "For living": "למגורים",
    Fr: "שישי",
    from: "מ",
    "Generate reports": "יצירת דוחות",
    "Has an elevator": "יש מעלית",
    "Have matches": "יש התאמות",
    "Highest price": "המכיר הכי יקר",
    Home: "בית",
    House: "בית פרטי",
    "Incoming Calls": "שיחות נכנסות",
    "Incoming calls": "שיחות נכנסות",
    "Insert a link to a YouTube video": "הוסף קישור לסרטון יוטיוב",
    "Invalid code": "קוד לא תקין",
    ipsum: "איפסום",
    "Join an agency": "הצטרף לסוכנות",
    "Last call": "שיחה אחרונה",
    "Last name": "שם משפחה",
    "Launch camera": "הפעל מצלמה",
    "Log out": "להתנתק",
    "Login to the app": "התחבר לאפליקציה",
    Lorem: "לורם",
    "Lowest price": "המחיר הנמוך ביותר",
    "m²": 'מ"ר',
    Madlan: "מדלן",
    Mamad: 'ממ"ד',
    Matches: "התאמות",
    Missed: "החמצה",
    MLS: "MLS",
    Mo: "שני",
    month: "חודש",
    Monthly: "חודשי",
    More: "יותר",
    "Most recent": "הכי עדכני",
    Mute: "להשתיק",
    "My profile": "הפרופיל שלי",
    Neighborhood: "שכונה",
    "New search": "חיפוש חדש",
    No: "לא",
    "No account?": "אין חשבון?",
    "No tasks": "אין משימות",
    "Not client": "לא לקוח",
    "Not the first": "לא קומה ראשונה",
    "Not the last": "לא קומה אחרונה",
    Notes: "הערות",
    of: "מתוך",
    Ok: "בסדר",
    "Only MLS": "רק MLS",
    "Only RealEstate": 'רק נדל"ן',
    optional: "אופציונאלי",
    Owner: "בעלים",
    Parking: "חניה",
    "per m²": 'למ"ר',
    "Per month": "לחודש",
    Annually: "בחיוב שנתי",
    Phone: "מכשיר טלפון",
    "Photo and video": "תמונה ווידאו",
    "Please enter valid Email address": "נא להזין כתובת אימייל חוקית",
    "Please enter your {{field}}": "נא להזין את ה-{{field}} שלך",
    "Please enter your address": "נא להזין כתובת",
    "Please enter your Agency Name": "נא להזין את שם המשרד",
    "Please enter your apt": "נא להזין מס דירה",
    "Please enter your area": "נא להזין שטח",
    "Please enter your building": "נא להזין מס' בניין",
    "Please enter your city": "נא להזין עיר",
    "Please enter your First Name": "נא להזין שם פרטי",
    "Please enter your Email": "נא להזין כתובת דוא״ל",
    "Please enter your floor": "נא להזין קומה",
    "Please enter your floor count": "נא להזין מספר הקומות בבניין",
    "Please enter your Last Name": "נא להזין שם משפחה",
    "Please enter your phone": "נא להזין מס' טלפון",
    "Please enter your price": "נא להזין מחיר",
    "Please enter your rooms": "נא להזין מס' חדרים",
    "Please enter your seller": "נא להזין פרטי המוכר",
    "Please enter your street": "נא להזין שם רחוב",
    "Use WhatsApp Business": "להשתמש בווטסאפ עסקי?",
    Price: "מחיר",
    "privacy policy": "מדיניות פרטיות",
    Profile: "פּרוֹפִיל",
    "Profile saved successfull": "הפרופיל נשמר בהצלחה",
    Properties: "נכסים",
    Property: "נכס",
    "Property matches": "התאמות נכסים",
    "Property type": "סוג נכס",
    "Purpose of purchase": "מטרת הרכישה",
    recommended: "מומלץ",
    Rent: "השכרה",
    Request: "בקשה",
    "Resend code": "שלח קוד שוב",
    Rooms: "חדרים",
    rooms: "חדרים",
    Sa: "שבת",
    Save: "שמור",
    Search: "לחפש",
    Select: "בחר",
    "Select a user": "בחר משתמש",
    "Select date": "בחר תאריך",
    "Select from gallery": "בחר מתוך הגלריה",
    "Select the account type": "בחר את סוג החשבון",
    "Select Type": "סוג חשבון",
    "Select user": "בחר משתמש",
    "Select your role": "בחר את התפקיד שלך",
    Sell: "מכירה",
    Seller: "מוכר",
    "send Verification code": "שלח קוד אימות",
    Settings: "הגדרות",
    Show: "להציג",
    "Show in call": "הצג בשיחה",
    "Show in MLS": "הצג ב-MLS",
    "Show on call preview": "הצג בחלונית שיחה נכנסת",
    "Some welcome text here": "קצת טקסט ברוך הבא",
    Speeker: "דובר",
    Spidil: "ספידיל",
    Status: "סטָטוּס",
    Street: "רְחוֹב",
    Su: "ראשון",
    "No elevator": "ללא מעלית",
    Advertised: "פורסם ב-",
    // Advertised: 'פורסם',
    Subscription: "מִנוּי",
    Success: "הַצלָחָה",
    Tasks: "משימות",
    Terms: "תנאים",
    "terms and conditions": "תנאים",
    Th: "חמישי",
    "The invitation code was sent by your supervisor via SMS along with a link to this application":
      "קוד ההזמנה נשלח על ידי הממונה שלך באמצעות SMS יחד עם קישור לאפליקציה זו",
    Title: "כותרת",
    to: "עד",
    Today: "היום",
    Tu: "שלישי",
    Type: "סוג",
    "Types of contacts": "סוגי אנשי קשר",
    Unknown: "לא ידוע",
    "up to": "עד ל",
    Verify: "תאשר",
    Video: "וִידאוֹ",
    View: "הצג",
    "View other plans": "צפה בתוכניות אחרות",
    We: "רביעי",
    "Welcome!": "ברוך הבא!",
    "Without matches": "בלי התאמות",
    "Yad 2": "יד 2",
    year: "שנה",
    Yes: "כן",
    YES: "כן",
    // Phones: '',
    Yesterday: "אתמול",
    "You can create your own agency or join an existing agency":
      "אתה יכול ליצור סוכנות משלך או להצטרף לסוכנות קיימת",
    "You only work with your personal clients and deals":
      "אתה עובד רק עם הלקוחות האישיים והעסקאות שלך",
    "Your code": "הקוד שלך",
    "Your phone number": "מספר הטלפון שלך",
    "You can add new agents, view all the properties and clients of your agency":
      "באפשרותך לצרף סוכנים חדשים ולראות את כל הנכסים וכל הלקוחות בסוכנות",

    //////////////////
    "Check your SMS messages. We'му sent you the code at": "קוד אמות  נשלח בהודעת סמס למספר טלפון",

    "{{field}} must be {{length}} characters": "{{field}} חייב להיות {{length}} תווים",
    "Select seller": "להוסיף מוכר",
    "Add seller": "הוסף מוכר",

    Apartment: "דירה",
    "Garden apartment": "דירת גן",
    "Private house/cottage": "בית פרטי/קוטג",
    "Roof/penthouse": "גג/פנטהוז",
    Duplex: "דופלקס",

    "Initial contact": "קשר ראשוני",
    Discussion: "משא ומתן",
    "Decision making": "קבלת החלטות",
    // 'Contract discussion': 'מסע ומתן',
    "Closed — won": "סגור, מוצלח",
    "Closed — lost": "סגור, נכשל",
    "Invite code": "קוד חיבור סוכן",
    // optional: '',
    // Flexible: '',
    "MLS Notes": "תנאי השת״פ",

    Meeting: "פגישה",
    Task: "משימה",
    Reminer: "תזכורת",
    Follow: "פולואפ",
    "Phone call": "טלפון",
    Urgent: "דחוף",
    Marketing: "שיווק",
    Advertising: "פרסום",
    "Customer arrival": "מקור הגעת לקוח",
    "Select from list": "בחר מרשימה",
    "General settings": "הגדרות כלליות",
    "Showing {{start}} - {{end}} of {{total}} records":
      "הוצג {{start}} - {{end}} מי {{total}} רשומות",
    Checked: "סמן שנבדק",
    Reports: "דיווחים",
    Articles: "מאמרים",
    Categories: "קטגוריות",
    "Professional information": "מידע מקצועי",
    "Professional Information": "מידע מקצועי",
    "Add new article": "הוסף כתבה חדשה",
    "Email subject": 'נושא הדוא"ל לזה',
    "Enter your subject": "הזן את הנושא שלך",
    Body: "גוף",
    "Please enter a subject": "בבקשה הזן נושא",
    "Changes saved successfully!": "השינויים נשמרו בהצלחה!",
    "Gold Plan": "תוכנית זהב",
    "Basic Plan": "תוכנית בסיסית",
    "Premium Plan": "תוכנית פרימיום",
    "Free Plan": "תוכנית חינמית",
    General: "כללי",
    "Caregiver name": "שם המטפל",
    "Employment start date": "תאריך תחילת עסקה",
    Finance: "לְמַמֵן",
    Conditions: "תנאים",
    "Base salary": "משכורת יסוד",
    Saturdays: "שבתות",
    "Pocket money in case": "דמי כיס במזומן",
    "Pocket money for a week": "דמי כיס לשבוע",
    "Additional Undertakings": "התחייבויות נוספות",
    "not set": "לא מוגדר",
    Pension: "פֶּנסִיָה",
    Holidays: "חגים",
    Recovery: "התאוששות",
    Vacation: "חוּפשָׁה",
    "Redeeming vacation days at the end of the transaction": "מימוש ימי חופשה בתום העסקה",
    "Redemption of vacation days at end of calendar year": "פדיון ימי חופשה בסוף שנה קלנדרית",
    "Payment of recovery fees at the end of a working year": "תשלום דמי הבראה בתום שנת עבודה",
    "Monthly recuperation fee-recommended": "דמי שיקום חודשי - מומלץ",
    "The therapist will work in part/ We haven't decided yet":
      "הטיפולנית תעבוד בחלק / עדיין לא החלטנו",
    "The therapist will work all holiday days": "הטיפולנית תעבוד בכל ימי החג",
    "Caregiver won't work on holiday days": "המטפלת לא תעבוד בימי החג",
    "Provision each month into salary": "הקצאה כל חודש לשכר",
    "Payment at the end of the transaction": "תשלום בסוף העסקה",
    "View document": "הצג מסמך",
    "No contract found": "אין חוזה נמצא",
    Diary: "יומן",
    Hollyday: "חג",
    "Weekly rest day": "יום מנוחה שבועי",
    Illness: "מַחֲלָה",
    "Plan Settings": "הגדרות תוכנית",
    "Price Plan Settings": "בקרת שכר",
    "Minimum enter wage": "שכר כניסה מינימלי",
    "Please enter a miniman wage": "אנא הזן שכר מינימלי",
    vacation_day: "סכום יום החופשה ",
    "Please enter a vacation day": "נא להזין סכום של יום חופשה",
    recovery_day_amount: "סכום ימי החלמה",
    "Please enter a recovery day amount": "אנא הזן סכום ימי החלמה",
    national_insurance_percentage: "אחוז ביטוח לאומי",
    "Please enter a national insurance percentage": "אנא הזן אחוז ביטוח לאומי",
    "Please enter a valid number": "נא להזין מספר",
    "please enter a number without dot": "נא הכנס מספר תקף",
    "price plan": "הגדרות מחיר תוכנית",
    price: "מחיר",
    "Please enter a price": "נא להזין מחיר",

    Features: "תכונות",
    "Create new Feature": "צור תכונה חדשה",
    "Edit feature": "ערוך תכונה",
    "Add feature": "הוסף תכונה",
    "English title": "כותרת באנגלית",
    "English title is required!": "כותרת באנגלית היא חובה!",
    "Hebrew title": "כותרת בעברית",
    "Russian title": "כותרת ברוסית",
    "Hebrew title is required!": "כותרת בעברית היא שדה חובה!",
    "Russian title is required!": "כותרת ברוסית נדרשת!",
    "Select Plan": "בחר תוכנית",
    "Please select a plan!": "בבקשה בחר תוכנית!",
    Submit: "שלח",
    "Title (English)": "כותרת (אנגלית)",
    "Title (Hebrew)": "כותרת (עברית)",
    "Title (Russian)": "כותרת (רוסית)",
    Plans: "תוכניות",
    Actions: "פעולות",
    "Data added successfully!": "הנתונים נוספו בהצלחה!",
    "Data updated successfully!": "הנתונים עודכנו בהצלחה!",
    "Feature deleted successfully": "התכונה נמחקה בהצלחה",
    "Blank space not allow": "רווחים ריקים אסורים",
    Suspended: "מוּשׁהָה",
    Verified: "מְאוּמָת",
    "Not verified": "לא מאומת",
  },
};

export default he;
