import {lazy, Suspense} from 'react';
import {Routes, Route, Link} from 'react-router-dom';
import {Col, Image, Layout, Row} from 'antd';

import logo from '../assets/img/logo.png';

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));

const GuestRouter = () => {
  return (
    <Suspense fallback={null}>
      <Layout style={{minHeight: '100vh'}}>
        <Layout.Content>
          <Row justify={'center'} align={'middle'} style={{minHeight: '100vh'}}>
            <Col span={6} style={{minWidth: '450px'}}>
              <h1>
                <Link
                  to='/'
                  style={{
                    display: 'flex',
                    margin: '0 auto 25px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Image src={logo} preview={false} width={100} />
                  &nbsp;&nbsp;&nbsp;Admin
                </Link>
              </h1>
              <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/login' element={<Login />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route
                  path='/reset-password/:token'
                  element={<ResetPassword />}
                />
                <Route element={<Login />} />
              </Routes>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </Suspense>
  );
};

export default GuestRouter;
